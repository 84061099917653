import React, { useEffect, useState } from "react"
import "./Home.css"
import Blocks from "../Blocks/Blocks"
import { NavLink } from "react-router-dom"
import ico from '.././../img/ico.svg'
// import qrcode from '.././../img/qr-code.svg'
import logoMobile from '.././../img/logoMobile.svg'
import OurAdvantages from "../OurAdvantages/OurAdvantages"
import QRCodeAndroid from '../../img/qr-code-android-v1.svg'

const Home = ({ color, data, datas }) => {
  const [modal, setModal] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('MobileAppPromo') !== 'true') {
      setModal(true)
    }
  })

  function closeModal() {
    setModal(false)
    localStorage.setItem('MobileAppPromo', 'true')
  }

  return (
    <div className="home">
      {/* {modal === true && (
      <div className="modal-window">
        <div className="modal-overlay"></div>
        <div className="modal-text">
          <div className="modal-mobile-app"></div>
          <div className="modal-text-wrap">
            <h1>Мобильное приложение XBT</h1>
            <p>Мы собрали весь функционал криптообменника XBT в мобильном приложении.</p>
            <div className="app-links">
              <a href="https://play.google.com/store/apps/details?id=com.navisdevs.xbt" target="_blank" className="android">
                <div>
                  <span>Download from</span>
                  <b>Google play</b>
                </div>
              </a>
              <a className="ios">
                <div>
                  <span>Download from</span>
                  <b>Apple Store</b>
                </div>
                <small className="qr-code-soon">Скоро</small>
              </a>
            </div>
            <button className="btn" onClick={() => { closeModal() }}>Понятно</button>
          </div>
        </div>
      </div>
      )} */}
      <div className="container">
        <div className="wrapper">
          <img style={{ width: "80%" }} src={ico} alt="" />
          <p>
            - идеальный способ купить или продать криптовалюту не выходя из дома
          </p>
          <p className="Transparent">
            Прозрачные комиссии, наличие лицензии, большие резервы и отличная
            репутация гарантируют успех ваших обменных операций!
          </p>
          <div className="home-button-wrap">
            <img src={QRCodeAndroid} alt="XBT LLC" />
            <div>
              <h5>Отсканируйте, чтобы загрузить приложение</h5>
              <h5 style={{ color: "#73F4AD" }}>iOS и Android</h5>
            </div>
          </div>
        </div>
        <div className="calas">
          <img style={{ width: "100%" }} src={logoMobile} alt="" />
        </div>
      </div>
      <div className="container_block">
        <Blocks data={data} datas={datas} />
        <OurAdvantages />
      </div>
    </div>
  )
}

export default Home
