import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../api";
import "./PrivacyAll.css";
const Terms = () => {
  const [content, setContent] = useState('')

  useEffect(() => {
    axios
      .get(url + `/pages/terms`)
      .then((response) => {
        if(response.data.detail.content) {
          setContent(response.data.detail.content)
        }
      })
  }, []);

  return (
    <div>
      <div className="container">
        <div className="privacy-wrap" dangerouslySetInnerHTML={{__html: (content) ? content : ''}}>
          {/* <h1 className="text-v1 text-center">Условия использования</h1>
          <p className="text-v4 py-4">ПРАВИЛА направленные на обеспечение выполнения требований законодательства о противодействии легализации доходов, полученных от преступной деятельности и финансированию терроризма/экстремизма (далее по тексту - Правила)</p>
          <p className="text-v3 pb-2">Настоящие Правила разработаны Обществом с ограниченной ответственностью «ОсОО «Кейджи Блокчейн Солушинс»» (далее по тексту - Общество) в соответствии с Законом Кыргызской Республики «О противодействии финансированию террористической деятельности и легализации (отмыванию) преступных доходов», Положением «Об общих требованиях к программе внутреннего контроля» и иными нормативным правовыми актами Кыргызской Республики, определяющие порядок организации и осуществления внутреннего контроля, направленных на обеспечение выполнения требований законодательства о противодействии легализации доходов, полученных, от преступной деятельности и финансированию терроризма/экстремизма и/или распространения оружия массового уничтожения. Система внутреннего контроля организуется с учетом особенностей осуществления деятельности в области оборота крипто-активов, основных направлений деятельности, клиентской базы и уровня рисков, связанных с клиентами и их операциями.</p>
          <p className="text-v4 py-4">Глава 1. Общие положения</p>
          <p className="text-v3 pb-2">
            • В настоящих Правилах используются следующие основные понятия:
            <br />• бенефициарный собственник — физическое лицо, которое в конечном итоге владеет правами собственности или контролирует клиента, в том числе юридическое лицо, в интересах которого совершается операция с денежными средствами, виртуальными активами и/или иным имуществом;
            <br />• виртуальный актив - совокупность данных в электронно-цифровой форме, имеющая стоимость, являющаяся цифровым выражением ценности и/или средством удостоверения имущественных и/или неимущественных прав, которая создается, хранится и обращается с использованием технологии распределенных реестров или аналогичной технологии и не является денежной единицей (валютой), средством платежа и ценной бумагой;
            <br />• внутренний контроль (комлаенс контроль) — деятельность сотрудников и должностных лиц Общества, осуществляющих деятельность в области оборота виртуальных активов, по надлежащей проверке клиента, управлению рисками, связанные с легализацией доходов, полученных от преступной деятельности и финансированием терроризма/экстремизма и/или распространения оружия массового уничтожения, выявлению подозрительных операций, а также операций, участниками которых являются лица, участвующие или подозреваемые в участии в деятельности по легализации доходов, полученных от преступной деятельности и финансированию терроризма/экстремизма и/или распространения оружия массового уничтожения;
            <br />• верификация — процедура проверки идентификационных данных клиента и/или бенефициарного владельца (выгодоприобретателя);
            <br />• идентификация — процедура установления идентификационных данных о клиенте и/или бенефициарном владельце (выгодоприобретателя);
            <br />• клиент — физическое или юридическое лицо, пользующееся услугами лиц, осуществляющих деятельность в области оборота крипто-активов;
            <br />• надлежащая проверка клиента — процедура проверки клиента и лиц, от имени которых он действует, идентификация бенефициарного собственника клиента, а также проведение на постоянной основе изучения деловых отношений и операций, осуществляемых клиентом, в целях проверки их соответствия сведениям о нем и его деятельности;
            <br />• операции, подлежащие сообщению — совершаемые и подготавливаемые операции с виртуальными активами, денежными средствами или иным имуществом, признанные лицами, осуществляющими деятельность в области оборота крипто-активов, подозрительными в ходе проведения комплаенс контроля;
          </p>
          <p className="text-v3 pb-2">
            • служба внутреннего контроля (комплаенс контроль) - уполномоченный сотрудник или подразделение, ответственный/ответственные лицо/лица за организацию и осуществление деятельности службы комплаенс контроля;
            <br />• высокорискованные страны - государства и территории (образования), которые не применяют или применяют в недостаточной степени международные стандарты по противодействию отмыванию денег, финансированию терроризма и финансированию распространения оружия массового уничтожения, а также оффшорные зоны;
            <br />• оффшорная зона — государства и территории, предоставляющие льготный налоговый режим и (или) не предусматривающие раскрытия и представления информации при проведении финансовых операций;
            <br />• санкционный перечень — перечень физических и юридических лиц, групп и организаций, в отношении которых имеются сведения об их участии в террористической или экстремистской деятельности и распространении оружия*массового уничтожения;
            <br />• подозрительная операция — находящаяся в процессе подготовки, совершения или совершенная операция с виртуальными активами, денежными средствами и/или иным имуществом, в отношении которой у лиц, осуществляющих деятельность в области оборота виртуальных активов, при реализации настоящих Правил возникли подозрения об ее осуществлении с целью легализации доходов, полученных от преступной деятельности и финансированию терроризма/экстремизма и/или распространения оружия массового уничтожения;
            <br />• риск — риск совершения клиентами операций в целях легализации доходов, полученных от преступной деятельности и финансированию терроризма/экстремизма и/или распространения оружия массового уничтожения;
            <br />• система внутреннего контроля — совокупность действий службы внутреннего контроля, осуществляющей деятельность в области оборота виртуальных активов, направленных на достижение целей и выполнение задач, определенных настоящими Правилами и внутренними документами Общества;
            <br />• уполномоченный государственный орган — Государственная служба финансовой разведки при Министерстве финансов Кыргызской Республики;
            <br />• участники операции — клиенты (в том числе учредители, акционеры и иные участники) и их представители, а также партнеры клиента, участвующие в операции.
            <br />• Целями внутреннего контроля являются:
            <br />• эффективное выявление и пресечение операций с денежными средствами, виртуальными активами и/или иным имуществом, направленных на легализацию доходов, полученных от преступной деятельности, финансирование терроризма/экстремизма и финансирование распространения оружия массового уничтожения;
            <br />• недопущение умышленного или неумышленного вовлечения лиц в преступную деятельность, осуществляющих деятельность в области оборота виртуальных активов;
            <br />• выявление, оценка, документальное фиксирование и снижение рисков;
            <br />• обеспечение неукоснительного соблюдения требований законодательства о противодействии легализации доходов, полученных от преступной деятельности и финансированию терроризма/экстремизма и/или распространения оружия массового уничтожения.
            <br />• Основными задачами внутреннего контроля являются:
            <br />• принятие соответствующих мер по выявлению, оценке, документальному фиксированию и снижению своих рисков;
            <br />• осуществление процедур идентификации/верификации и мер по надлежащей проверке клиентов, в том числе верификации и регулярного обновления данных о клиентах и их бенефициарных собственниках;
            <br />• идентификация/верификация бенефициарных собственников, принятие доступных мер по проверке их личности;
            <br />• осуществление углубленного мониторинга операций проводимые публичными должностными лицами, членами их семей и лицами, близкими к публичным должностным лицам;
            <br />• выявление подозрительных операций в порядке, установленном настоящими Правилами;
            <br />• своевременное предоставление в специально уполномоченный государственный орган информации (документов) о подозрительных операциях, выявленных в ходе осуществления внутреннего контроля;
            <br />• своевременное исполнение запросов о предоставлении дополнительной информаций, а также предписаний специально уполномоченного государственного органа о приостановлении операций с денежными средствами, виртуальными активами или иным имуществом клиентов;
            <br />• выявление среди участников операции лиц, участвующих или подозреваемых в участии в террористической деятельности или распространении оружия массового уничтожения путем сверки с санкционным перечнем;
            <br />• обеспечение конфиденциальности сведений, связанных с противодействием легализации доходов, полученных от преступной деятельности и финансированию терроризма/экстремизма и/или распространения оружия массового уничтожения;
            <br />• обеспечение хранения информации об операциях, а также идентификационных данных и материалов по надлежащей проверке клиентов в течение сроков, установленных актами законодательства;
            <br />• оперативное и систематическое обеспечение руководства лиц, осуществляющих деятельность в области оборота виртуальных активов, достоверной информацией и материалами, необходимыми для принятия соответствующих решений;
            <br />• формирование базы данных об осуществлении или попытках осуществления подозрительных операций, участниках операций, осуществивших подозрительные операции, а также взаимообмен такой информацией с другими лицами, осуществляющими деятельность в области оборота виртуальных активов и государственными органами в соответствии с актами законодательства;
            <br />• выявление по запросам в клиентской базе лиц, связанных с финансированием террористической деятельности или финансированием распространения оружия массового уничтожения, путем сверки с санкционным перечнем;
            <br />• совершение иных действий, направленных на обеспечение выполнения требований законодательства о противодействии легализации доходов, полученных от преступной деятельности и финансированию терроризма/экстремизма.
          </p>
          <p className="text-v4 py-4">Глава 2. Процедуры осуществления правил внутреннего контроля</p>
          <p className="text-v3 pb-2">
            1. Процедура идентификации и верификации клиента и бенефициарного собственника (выгодоприобретателя).
            <br />Общество в целях идентификации/верификации личности каждого клиента использует процедуру KYC (знай своего клиента). Общество обязано идентифицировать как лиц, которые принимаются на обслуживание для установления длительных отношений, так и лиц, которым оказываются услуги, либо с которыми совершаются операции (сделки) с денежными средствами или виртуальными активами разового характера, вне зависимости от вида, характера и размера оказываемых услуг, либо совершаемых операций (сделок).
            <br />Если от имени физического или юридического лица действует его представитель, Общество обязано идентифицировать/верифицировать представителя, проверить его полномочия, а также принимать доступные и необходимые меры по установлению и идентификации/верификации бенефициарного собственника (выгодоприобретателя).
            <br />Требование об идентификации/верификации бенефициарного собственника (выгодоприобретателя) считается выполненным, если Общество на основании соответствующих сведений, подтвердит, что ими предприняты обоснованные и доступные в сложившихся обстоятельствах меры по установлению и идентификации/верификации бенефициарного собственника (выгодоприобретателя).
            <br />Идентификацию/верификацию осуществляет Служба внутреннего контроля Общества, если иное не предусмотрено настоящим Правилами.
            <br />Все документы, позволяющие идентифицировать/верифицировать клиента и бенефициарного собственника (выгодоприобретателя), должны быть действительными на дату их предъявления.
            <br />Документы, составленные полностью или в какой-либо их части на иностранном языке, должны быть представлены надлежащим образом, заверенным переводом на государственный и/или официальный язык. Документы, исходящие от государственных органов иностранных государств, подтверждающие статус юридических лиц - нерезидентов, принимаются в случае их легализации в установленном порядке (указанные документы могут быть представлены без их легализации в случаях, предусмотренных международным договором Кыргызской Республики).
            <br />Все документы должны быть представлены клиентами в подлиннике или надлежащим образом заверенной копии. Если к идентификации/верификации клиента и бенефициарного собственника (выгодоприобретателя) имеет отношение только часть документа, может быть представлена заверенная выписка из него.
            <br />В случае представления копий документов служба внутреннего контроля Общества вправе потребовать представления подлинников документов для ознакомления/сличения.
            <br />Для идентификации/верификации клиента и/или бенефициарного собственника (выгодоприобретателя) используется анкетирование.
            <br />Сведения о клиенте и бенефициарном собственнике (выгодоприобретателе), полученные в результате идентификации/верификации, фиксируются в анкете клиента, составленной в зависимости от статуса клиента и бенефициарного собственника (выгодоприобретателя).
          </p>
          <p className="text-v3 pb-2">Анкета клиента может заполняться на бумажном носителе или в электронном виде. Анкета клиента, заполненная в электронном виде, при переносе на бумажный носитель заверяется подписью сотрудника службы внутреннего контроля Общества. Содержание анкеты клиента предусматривается Приложением №1 к настоящим Правилам. Анкета клиента подлежит хранению лицами, представляющими сведения, не менее 5 лет со дня контроля.</p>
          <p className="text-v3 pb-2">В случае изменения сведений, содержащихся в анкете клиента бенефициарного собственника (выгодоприобретателя), служба внутреннего Общества обязана предупредить клиентов о предоставлении клиентом обновленной информации и документов, не позднее одного месяца, со дня изменений. Служба внутреннего контроля Общества на основании сведений, полученных от клиента в день их получения вносить изменения в анкету клиента.</p>
          <p className="text-v3 pb-2">Сведения, указываемые в анкете клиента, могут фиксироваться и храниться в электронной базе данных, к которой сотрудникам Общества, осуществляющим идентификацию/верификацию клиента и бенефициарного собственника (выгодоприобретателя), в том числе службе внутреннего контроля, должен быть обеспечен оперативный доступ в постоянном режиме для проверки информации о клиенте и бенефициарном собственнике (выгодоприобретателе).</p>
          <p className="text-v3 py-2">Общество обязано:</p>
          <p className="text-v3 pb-2">
            <br />• провести повторную идентификацию/верификацию клиента и бенефициарного собственника (выгодоприобретателя), если у них возникают сомнения в достоверности сведений, полученных ранее в результате идентификации/верификации;
            <br />• проверить наличие в отношении клиента и бенефициарного собственника (выгодоприобретателя) сведений об их участии в террористической или экстремистской деятельности. Указанные сведения содержатся в «Перечне лиц, групп, организаций, в отношении которых имеются сведения об их участии в легализации (отмывании) преступных доходов», формируемый уполномоченным государственным органом.
          </p>
          <p className="text-v3 py-2">Общества периодически:</p>
          <p className="text-v3 pb-2">
            • обновляет сведения, полученные в результате идентификации/верификации клиента и бенефициарного собственника (выгодоприобретателя) (для лиц с высоким уровнем риска - не реже одного раза в год, для всех остальных - не реже одного раза в три года);
            <br />• пересматривает уровень (категории) Риска по мере изменения идентификационных сведений или изменения уровня (категории) Риска (категории клиента, места его регистрации, используемых им продуктов и услуг).
          </p>
          <p className="text-v3 py-2">Общество при обслуживании иностранных политически значимых лиц, помимо идентификации/верификации, должно:</p>
          <p className="text-v3 pb-2">
            • принимать доступные меры по выявлению иностранных политически значимых лиц среди физических лиц, принимаемых на обслуживание или находящихся на обслуживании;
            <br />• принимать на обслуживание иностранных политически значимых лиц только на основании письменного разрешения руководителя лиц, представляющих сведения;
            <br />• принимать доступные меры по определению источников происхождения денежных средств, виртуальных активов или имущества иностранных политически значимых лиц;
            <br />• на постоянной основе обновлять имеющуюся в распоряжении информацию об иностранных политически значимыхлицах, находящихся у них на обслуживании;
            <br />• уделять повышенное внимание операциям (сделкам) с денежными средствами, виртуальными активами или имуществом, осуществляемым иностранными политически значимыми лицами, их супругами, близкими родственниками (по прямой восходящей и нисходящей линии (родители и дети, дедушка, бабушка и внуки), полнородные и неполнородные (имеющие общих отца или мать) братья и сестры, усыновители и усыновленные), представителями и деловыми партнерами;
            <br />• удостовериться в полномочиях и подлинности лиц, имеющих права на распоряжение денежными средствами, виртуальными активами или имуществом.
          </p>
          <p className="text-v3 pb-2">Общество должно уделять особое внимание деловым отношениям и операциям (сделкам) с физическими и юридическими лицами, а также физическим и юридическим лицам, которые из (в):</p>
          <p className="text-v3 pb-2">
            • оффшорных зон/зонах, перечень которых утверждается Национальным банком Кыргызской Республики;	.
            <br />• высокорискованных стран/странах;
            <br />• США, либо являются резидентами США, данные которых будут передаваться в коммерческие банки Кыргызской Республики;
            <br />• государств и территорий, которые не применяют либо применяют в недостаточной степени рекомендации FATF, а также с дочерними организациями, филиалами и представительствами, основные компании которых зарегистрированы в таких государствах и территориях, а также лицам (перечень которых утверждается ГСФР).
            <br />• Процедура оценки Риска совершения клиентом операций (сделок), связанных с финансированием терроризма (экстремизма) или легализацией (отмыванием) доходов, полученных преступным путем.
          </p>
          <p className="text-v3 pb-2">Оценка Риска производится при установлении деловых отношений с клиентом и после проведения идентификации/верификации клиента и бенефициарного собственника (выгодоприобретателя), а также иных участников операции (сделки). Для последующей оценки уровня (категории) Риска лицо, представляющее сведение, должно осуществлять постоянный мониторинг операций (сделками) клиента. Служба внутреннего контроля Общества на основании имеющейся информации и документов, оценивает уровень (категории) Риска и фиксирует его, с обоснованием оценки Риска.</p>
          <p className="text-v3 pb-2">Оценка Риска состоит из следующих последовательных процедур:</p>
          <p className="text-v3 pb-2">
            • накопление информации о деятельности клиента и/или бенефициарного собственника (выгодоприобретателя) на территории Кыргызской Республики и за ее пределами;
            <br />• в случае возникновения подозрения о том, что клиент осуществляет (или намеревается осуществить) операции (сделки), связанные с финансированием терроризма/экстремизма и легализацией (отмыванием) доходов, полученных преступным путем, сотрудник Общества обязан сообщить об этом службе внутреннего контроля Общества;
            <br />• служба внутреннего контроля Общества вправе принять решение о мониторинге клиента и совершаемых им операций (сделок), а также об отказе в заключении с таким клиентом операции (сделки).
          </p>
          <p className="text-v3 pb-2">Служба внутреннего контроля Общества для оценки Риска использует типичные критерии оценки уровня (категории) Риска, установленные согласно Положению «О порядке проведения оценки рисков финансирования террористической деятельности и легализации (отмывания) преступных доходов».</p>
          <p className="text-v3 pb-2">Если операция (сделка) соответствует, хотя бы одному из критериев, установленных согласно вышеназванному Положению, уровень (категория) Риска оценивается как высокий.</p>
          <p className="text-v3 pb-2">Служба внутреннего контроля Общества, должна уделять повышенное внимание операциям (сделкам), проводимым клиентом, операции (сделки) которого отнесены к высокому уровню (категории) Риска, в том числе путем осуществления постоянного мониторинга за операциями (сделками) такого клиента.</p>
          <p className="text-v3 pb-2">В целях проведения оценки степени (уровня) риска, а также последующего контроля за его изменением Общество осуществляет постоянный мониторинг операций (сделок) клиента по мере их совершеня.</p>
          <p className="text-v3 pb-2">Процедура выявления в деятельности клиента операций (сделок), подлежащих обязательному контролю, и (или) подозрительных операций (сделок), а также иных операций (сделок), непосредственно связанных с финансированием терроризма (экстремизма) или легализацией (отмыванием) доходов, полученных преступным путем.</p>
          <p className="text-v3 pb-2">Выявление операций (сделок), подлежащих обязательному контролю, производится на основании критериев, предусмотренных Законом Кыргызской Республики «О противодействии финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем».</p>
          <p className="text-v3 pb-2">Выявление подозрительных операций (сделок), производится в соответствии с перечнем критериев подозрительных операций (сделок), установленных в приложении 3 к приказу уполномоченного государственного органа от 17 апреля 2015 года № 30/П (коды 20000- 90099).</p>
          <p className="text-v3 pb-2">Сотрудники Общества при осуществлении операций (сделок), подлежащих обязательному контролю, и (или) подозрительных операций (сделок) в обязательном порядке, в соответствии с предусмотренными критериями, обязаны информировать (по форме, утвержденной руководителем) службу внутреннего контроля Общества, а также представлять ему сведения о клиенте.</p>
          <p className="text-v3 pb-2">случае получения сведений о необычной (подозрительной) операции, служба внутреннего контроля Общества изучает представленную ей и всю имеющуюся дополнительную информацию о клиенте и его деятельности, и принимает решение о целесообразности направления сообщения в уполномоченный государственный орган.</p>
          <p className="text-v3 pb-2">Служба внутреннего контроля Общества проводит ежедневный анализ всех операций (сделок) и при выявлении операций (сделок), подлежащих обязательному контролю, и (или) подозрительных операций (сделок) обязана составлять Сообщения о них и в установленном порядке направлять в уполномоченный государственный орган.</p>
          <p className="text-v3 pb-2">• Процедура проверки информации о клиенте или об операции (сделке) клиента, о бенефициарном собственнике (выгодоприобретателе) для подтверждения обоснованности подозрений осуществления финансирования терроризма (экстремизма) или легализации (отмывания) доходов, полученных преступным путем.</p>
          <p className="text-v3 pb-2">При поступлении информации о подозрительной операции (сделке) служба внутреннего контроля Общества осуществляет дополнительный сбор информации о клиенте и бенефициарном собственнике (выгодоприобретателе) или операции (сделке) клиента. В этих целях в Обществе устанавливается следующая процедура:</p>
          <p className="text-v3 pb-2">• сотрудник Общества, выявивший подозрительную операцию (сделку) передает информацию в службу внутреннего контроля Общества в порядке, установленном внутренними документами Общества;</p>
          <p className="text-v3 pb-2">• при наличии оснований полагать, что операция (сделка) клиента относится к подозрительной операции (сделки), служба внутреннего контроля Общества принимает решение о признании операции (сделки) клиента подозрительной операцией (сделкой) с последующим предоставлением сообщений в уполномоченный государственный орган;</p>
          <p className="text-v3 pb-2">• при выявлении в деятельности клиента подозрительной операции (сделки), служба внутреннего контроля Общества должна принять предварительное решение о дальнейших действиях Общества, в отношении клиента и его операции (сделки), следующего характера:</p>
          <p className="text-v3 pb-2">• обратиться к клиенту с просьбой о предоставлении необходимых объяснений/разъяснений, в том числе дополнительных сведений, разъясняющих экономический смысл подозрительной операции (сделки);</p>
          <p className="text-v3 pb-2">• запросить у клиента дополнительные документы, в том числе контракты, договоры и иные документы, являющиеся основанием для совершения операции (сделки);</p>
          <p className="text-v3 pb-2">• обеспечить повышенное внимание в соответствии с настоящими общими требованиями ко всем операциям (сделкам) клиента, проводимым через Общество;</p>
          <p className="text-v3 pb-2">• предпринять иные необходимые действия при условии соблюдения законодательства Кыргызской Республики;</p>
          <p className="text-v3 pb-2">• при признании операции (сделки) клиента как подозрительной операцией (сделкой), служба внутреннего контроля Общества, в день их признания, составляет Сообщение о подозрительной операции (сделке) - документ, содержащий сведения об операции (сделке), и направляет в уполномоченный государственный орган. Сообщение предоставляется в уполномоченный государственный орган, согласно типовой форме, утвержденный уполномоченным государственным органом и в соответствии с Законом Кыргызской Республики «О противодействии финансированию террористической деятельности и легализации (отмыванию) преступных доходов»;</p>
          <p className="text-v3 pb-2">• сотрудники Общества участвующие в осуществлении операций (сделок), обязаны принять необходимые меры, с целью проверки наличия в отношении клиента, бенефициарного собственника (выгодоприобретателя), сведений об их участии в террористической или экстремистской деятельности. Указанные сведения содержатся в Перечне лиц, групп, организаций, в отношении которых имеются сведения об их участии в легализации (отмывании) преступных доходов, формируемый уполномоченным государственным органом;</p>

          <p className="text-v3 pb-2">• в случае, если у сотрудников Общества, участвующих в осуществлении операций (сделок), возникают подозрения, что какие-либо операции (сделки) осуществляются в целях финансирования терроризма (экстремизма) или легализации (отмывания) доходов, полученных преступным путем, они обязаны информировать службу внутреннего контроля Общества для направления в установленном порядке в уполномоченный государственный орган независимо от того, относятся или не относятся они к операциям (сделкам), предусмотренным Законом Кыргызской Республики «О противодействии финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем» или установленным уполномоченным государственным органом критериев (признаков) подозрительных операций (сделок).</p>
          <p className="text-v3 pb-2">• Процедура документального фиксирования информации:</p>
          <p className="text-v3 pb-2">Сотрудники Общества фиксируют информацию и собирают документы об операциях (сделках) клиента таким образом, чтобы в случае необходимости было возможно воспроизвести детали операции (сделки) (в том числе сумму операции (сделки), валюту платежа, данные о контрагенте клиента и другие) и могли быть использованы в качестве доказательства в уголовном и/или гражданском судопроизводствах. Порядок документального фиксирования операций (сделок) должен предусматривать фиксирование:</p>
          <p className="text-v3 pb-2">• вида операции (сделки) и основания ее совершения;.</p>
          <p className="text-v3 pb-2">• даты совершения операции (сделки) и суммы, на которую она совершена;</p>
          <p className="text-v3 pb-2">• сведений, полученных в результате идентификации физического или юридического лица;</p>
          <p className="text-v3 pb-2">• сведений, необходимых для идентификации физического или юридического лица, по поручению и от имени которого совершается операция (сделка), идентификационного номера налогоплательщика (при его наличии), адреса места жительства или места нахождения физического или юридического лица;</p>
          <p className="text-v3 pb-2">• сведений, необходимых для идентификации представителя физического или юридического лица, совершающего операцию (сделку) от имени другого лица в силу полномочий, основанных на доверенности, законе либо акте уполномоченного на то государственного органа или органа местного самоуправления, адреса места жительства представителя физического или юридического лица;</p>
          <p className="text-v3 pb-2">• сведений, необходимых для идентификации/верификации получателя по операции (сделке) с денежными средствами, виртуальными активами или имуществом, в том числе наименования получателя, номера его счета;</p>
          <p className="text-v3 pb-2">• других сведений, предусмотренных нормативными правовыми актами Кыргызской Республики.</p>
          <p className="text-v3 pb-2">Документальное фиксирование информации осуществляется на основании информации и документов, предоставляемых клиентами и других источников. Документы, позволяющие идентифицировать/верифицировать клиента и бенефициарного собственника (выгодоприобретателя) и иных участников операции (сделки), а также определить основания ее совершения, должны быть действительны на дату их предъявления.</p>
          <p className="text-v3 pb-2">При фиксировании информации и сборе документов для целей внутреннего контроля необходимо следить за тем, чтобы клиентами по мотивированному запросу были представлены необходимые обосновывающие документы (договоры, контракты и т.д.).</p>
          <p className="text-v3 pb-2">Способы фиксирования информации для целей противодействия финансированию терроризма (экстремизма) и легализации (отмыванию) доходов, полученных преступным путем, предусматриваемые в правилах внутреннего контроля, должны обеспечивать воспроизведение всех необходимых деталей операции (сделки).</p>
          <p className="text-v3 pb-2">Сотрудники Общества должны обеспечивать конфиденциальность фиксируемой информации об операциях (сделках), о клиенте, бенефициарном собственнике (выгодоприобретателе) и их представителях, а также об иных лицах, по поручению, от имени и (или) в интересах которых действуют клиенты, а также о мерах, принимаемых Общества в целях предотвращения и выявления операций (сделок), связанных с V финансированием терроризма (экстремизма) и легализацией (отмыванием) доходов, полученных преступным путем.</p>
          <p className="text-v3 pb-2">• Процедура хранения информации и документов, полученных в результате реализации, правил внутреннего контроля.</p>
          <p className="text-v3 pb-2">В Обществе предусматривается хранение следующих документов, полученных в соответствии с Законом Кыргызской Республики «О противодействии финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем»:</p>
          <p className="text-v3 pb-2">• оригиналы документов (заверенные в установленном порядке копии), содержащих сведения о клиенте и бенефициарном собственнике (выгодоприобретателе) и их представителях, или иных участниках операции (сделки), в течение не менее 5 лет с даты прекращения действия взаимных обязательств лиц, представляющих сведения, и клиента;</p>
          <p className="text-v3 pb-2">• оригиналы или копии (заверенные в установленном порядке) документов по соответствующим операциям (сделкам) клиентов и сообщений об операции (сделке) - в течение не менее 5 лет с даты прекращения действия взаимных обязательств лиц, представляющих сведения, и клиента;</p>
          <p className="text-v3 pb-2">• отчет в виде электронного документа, - в течение не менее 5 лет. В случае необходимости представления сведений, зафиксированных и хранящихся в электронном виде, в качестве доказательства в различных процессах, документ подписывается в установленном порядке для придания ему юридической силы;</p>
          <p className="text-v3 pb-2">• анкета, составленная по результатам идентификации и верификации, а также изучения клиента, - в течение не менее 5 лет. Сведения, указываемые в анкете клиента, фиксируются и хранятся в электронной базе данных (отчет в виде электронного документа), к которой ответственным сотрудникам Общества обеспечивается оперативный доступ в постоянном режиме для проверки информации о клиенте и бенефициарном собственнике (выгодоприобретателе).</p>
          <p className="text-v3 pb-2">• иные документы, в том числе деловую переписку, - в течение не менее 5 лет после прекращения обязательств между лицом, представляющим сведения, и клиентом.</p>
          <p className="text-v3 pb-2">Служба внутреннего контроля Общества осуществляет сбор, учет и хранение  отправленных в уполномоченный государственный орган сообщений об операциях (сделках), подлежащих обязательному контролю, и о подозрительных операциях (сделках),в установленном порядке.</p>
          <p className="text-v3 pb-2">Вышеуказанные документы должны собираться и храниться, в состоянии, в котором они могут быть использованы в качестве доказательства в уголовном и/или гражданском судопроизводствах.</p>
          <p className="text-v3 pb-2">• Процедура подготовки и обучения кадров в сфере противодействия финансированию терроризма (экстремизма) или легализации (отмыванию) доходов, полученных преступным путем.</p>
          <p className="text-v3 pb-2">Общество организует обучение своих сотрудников исходя из их должностных обязанностей по следующим направлениям:</p>
          <p className="text-v3 pb-2">• регулярное ознакомление с нормативной правовой базой по противодействию финансированию терроризма (экстремизма) и легализации (отмыванию) доходов, полученных преступным путем, в том числе нормативными документами, а также рекомендациями и разъяснениями уполномоченного государственного органа;</p>
          <p className="text-v3 pb-2">• ознакомление сотрудников с настоящими Правилами;</p>
          <p className="text-v3 pb-2">• проведение практических занятий по реализации настоящих Правил, в том числе процедур их осуществления;</p>
          <p className="text-v3 pb-2">• регулярное (не реже одного раза в год) направление сотрудников на семинары, лекции по вопросам противодействия финансированию терроризма (экстремизма) и легализации (отмыванию) доходов, полученных преступным путем, организуемые уполномоченным государственным органом;</p>
          <p className="text-v3 pb-2">• подготовка при необходимости памятки для сотрудников Общества по критериям операций (сделок) подлежащих, обязательному контролю и признакам подозрительных операций (сделок);</p>
          <p className="text-v3 pb-2">• направление, при наличии возможности, службы внутреннего контроля на конференции, семинары и иные форумы (в том числе международные), посвященные проблемам противодействия финансированию терроризма (экстремизма) и легализации (отмыванию) доходов, полученных преступным путем.</p>
          <p className="text-v3 pb-2">Требования к квалификации должностных лиц службы внутреннего контроля Общества и лиц, осуществляющих регистрацию операций (сделок), подлежащих обязательному контролю, и подозрительных операций (сделок), передачу, учет и хранение специальных формуляров и иных документов, связанных с осуществлением и (или) совершением операций (сделок), подлежащих обязательному контролю, и подозрительных операций (сделок), а также к лицам, ответственным за принятие организационных мер по осуществлению внутреннего контроля, определяются в соответствии с общими требованиями, установленным уполномоченным государственным органом.</p>
          <p className="text-v3 pb-2">Сотрудник службы внутреннего контроля Общества:</p>
          <p className="text-v3 pb-2">• должен иметь высшее юридическое или экономическое образование, а при отсутствии такого образования - опыт работы не менее двух лет на должностях, связанных с исполнением обязанностей по противодействию финансированию терроризма (экстремизма) и легализации (отмыванию) доходов, полученных преступным путем:</p>
          <p className="text-v3 pb-2">• должен обладать знаниями в области виртуальных активов;</p>
          <p className="text-v3 pb-2">• не должен иметь судимости;</p>
          <p className="text-v3 pb-2">• должен знать работу подразделений по работе с клиентами и других подразделений Общества, занимающихся совершением сделок с ценными бумагами.</p>
          <p className="text-v3 pb-2">• Процедура, определяющая порядок организации деятельности лица, предоставляющего сведения по отказу от принятия на обслуживание и выполнение распоряжений клиента об осуществлении операции (сделки).</p>
          <p className="text-v3 pb-2">Общество в целях противодействия финансированию терроризма (экстремизма) и легализации (отмыванию) доходов, полученных преступным путем, вправе отказать в принятии на обслуживание физического или юридического лица в следующих случаях:</p>
          <p className="text-v3 pb-2">• непредставление физическим или юридическим лицом документов/информации, необходимых для проведения надлежащей их верификации/идентификации/проверки;</p>
          <p className="text-v3 pb-2">• представление недостоверных документов физическим или юридическим лицом;</p>
          <p className="text-v3 pb-2">• наличия в отношении физического или юридического лица сведений об участии в террористической или экстремистской деятельности.</p>
          <p className="text-v3 pb-2">Общество вправе отказать в выполнении распоряжения клиента о совершении операции (сделок) (за исключением операций по зачислению денежных средств, поступивших на счет физического или юридического лица), по которой не представлены документы, необходимые для фиксирования в соответствии с положениями Закона Кыргызской Республики «О противодействии финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем», и в случае наличия в отношении участника операции (сделки) сведений об участии в террористической или экстремистской деятельности.</p>
          <p className="text-v3 pb-2">. Процедура, регламентирующая у лиц, представляющих сведения, деятельность по приостановлению операций (сделок) с денежными средствами, виртуальными активами или имуществом.</p>
          <p className="text-v3 pb-2">Деятельность по приостановлению отдельных видов операций (сделок) с денежными средствами, виртуальными активами или имуществом осуществляется в соответствии с требованиями Закона Кыргызской Республики «О противодействии финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем».</p>
          <p className="text-v3 pb-2">Сотрудники Общества обязаны приостанавливать операции (сделки) физических и юридических лиц, если имеются достоверные сведения об их участии в террористической или экстремистской деятельности (финансировании терроризма или экстремизма), на три рабочих дня от даты, когда распоряжение клиента о ее осуществлении должно быть выполнено, и не позднее дня приостановления операции (сделки) обязаны представлять информацию о ней в уполномоченный государственный орган.</p>
          <p className="text-v3 pb-2">При неполучении в течение указанного срока постановления уполномоченного государственного органа о замораживании соответствующей операции (сделки) физических и юридических лиц, в отношении которых имеются сведения об участии в террористической или экстремистской деятельности (финансировании терроризма или I экстремизма), на дополнительный срок, Общество осуществляет операцию (сделку) с денежными средствами, виртуальным активом или имуществом по распоряжению клиента.</p>
          <p className="text-v3 pb-2">При неполучении по истечении срока, указанного в постановлении уполномоченного государственного органа, постановления прокурора или следователя, санкционированного прокурором, о замораживании соответствующей операции (сделки) на дополнительный срок или о наложении ареста на денежные средства, виртуального актива или имущество, Общество осуществляют операцию (сделку) с денежными средствами, виртуальными активами или имуществом по распоряжению клиента.</p>
          <p className="text-v3 pb-2">• Процедура по организации у лиц, представляющих сведения, системы по реализации правил внутреннего контроля.</p>
          <p className="text-v3 pb-2">Службу внутреннего контроля Общества определяет руководитель Общества и назначает своим приказом сотрудника, ответственного за соблюдение правил внутреннего контроля и реализацию процедур их осуществления. При отсутствии в штате сотрудника Службы внутреннего контроля Общества права/обязанности/полномочия осуществляет руководитель Общества или замещающее его лицо.</p>
          <p className="text-v3 pb-2">Служба внутреннего контроля Общества независимо в своей деятельности от других</p>
          <p className="text-v3 pb-2">структурных подразделений Общества, подчиняется и подотчётно только руководителю Общества.</p>
          <p className="text-v3 pb-2">сновной задачей службы внутреннего контроля Общества является:</p>
          <p className="text-v3 pb-2">• исключение вовлечения Общества, и участия его служащих в осуществлении финансирования терроризма (экстремизма) и легализации (отмывания) доходов, полученных преступным путем;</p>
          <p className="text-v3 pb-2">• эффективная реализация законодательства по противодействию финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем;</p>
          <p className="text-v3 pb-2">• содействие уполномоченному государственному органу и иным надзорным органам в выполнении задач и функций в сфере противодействия финансированию терроризма (экстремизма) и легализации (отмыванию) доходов, полученных преступным путем.</p>
          <p className="text-v3 pb-2">Основными функциями службы внутреннего контроля Общества является:</p>
          <p className="text-v3 pb-2">• организация разработки и представление на утверждение руководителя Общества, правил внутреннего контроля, а также совершенствование методологии оценки рисков и процедур управления рисками;</p>
          <p className="text-v3 pb-2">• участие в идентификации/верификации клиентов и иных участников операций (сделок):</p>
          <p className="text-v3 pb-2">• мониторинг и анализ операций (сделок) клиентов;</p>
          <p className="text-v3 pb-2">• взаимодействие с уполномоченным государственным органом по вопросам предоставления информации об операциях (сделках), подлежащих обязательному контролю, и (или) о подозрительных операциях (сделках);</p>
          <p className="text-v3 pb-2">• организация реализации правил внутреннего контроля, в том числе процедур его осуществления. В этих целях ответственный сотрудник консультирует других сотрудников Общества, по вопросам, возникающим при реализации, правил внутреннего контроля;</p>
          <p className="text-v3 pb-2">• организация представления в уполномоченный государственный орган в соответствии с Законом Кыргызской Республики «О противодействии финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем» и иными нормативными правовыми актами Кыргызской Республики;</p>
          <p className="text-v3 pb-2">• в пределах предоставленных компетенций, оказание содействия уполномоченным представителям государственных органов при проведении ими инспекционных проверок деятельности Общества по вопросам соблюдения законодательства Кыргызской Республики о противодействии финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем;</p>
          <p className="text-v3 pb-2">• разработка форм отчетов и процедур их предоставления руководству Общества, в целях обеспечения правил внутреннего контроля;</p>
          <p className="text-v3 pb-2">• представление письменного отчета о результатах реализации правил внутреннего контроля руководству Общества, по мере необходимости, но не реже одного раза в квартал. Порядок текущей отчетности ответственного сотрудника определяется внутренними документами Общества.</p>
          <p className="text-v3 pb-2">Служба внутреннего контроля Общества наделяется следующими полномочиями:</p>
          <p className="text-v3 pb-2">• получать от руководителей и сотрудников Общества, необходимые документацию/информацию;</p>
          <p className="text-v3 pb-2">• снимать копии с полученных документов, получать копии необходимых файлов и записей, хранящихся в локальных вычислительных сетях и автономных компьютерных системах;</p>
          <p className="text-v3 pb-2">• в установленном порядке входить в помещения подразделений Общества, а также в помещения, используемые для хранения документов (архивы), наличных денег и ценностей (денежные хранилища), компьютерной обработки данных (компьютерные залы) и хранения данных на машинных носителях;</p>
          <p className="text-v3 pb-2">• давать временные в пределах сроков, установленных законодательством Кыргызской Республики для проведения операции (сделки) (до решения руководителя Общества), указания, касающиеся проведения операции (сделки) (в том числе предписания о приостановлении проведения операции (сделки), в целях получения дополнительной или проверки имеющейся информации о клиенте или операции (сделке);</p>
          <p className="text-v3 pb-2">• иными полномочиями в соответствии с настоящими общими требованиями и внутренними документами Общества.</p>
          <p className="text-v3 pb-2">При осуществлении своих функций служба внутреннего контроля Общества обязана:</p>
          <p className="text-v3 pb-2">• обеспечивать сохранность и возврат полученных от соответствующих подразделений Общества, документов;</p>
          <p className="text-v3 pb-2">• соблюдать конфиденциальность информации, полученной при осуществлении своих функций, и не разглашать данные о передаче информации уполномоченный государственный орган в соответствии с Законом Кыргызской Республики «О противодействии финансированию терроризма и легализации (отмыванию) доходов, полученных преступным путем»;</p>
          <p className="text-v3 pb-2">• выполнять иные обязанности в соответствии с настоящими общими требованиями и внутренними документами Общества.</p>
          <p className="text-v3 pb-2">Осуществление функций, установленных настоящими Правилами, относится к исключительной компетенции службы внутреннего контроля Общества, а при отсутствии в штате сотрудника Службы внутреннего контроля Общества права/обязанности/полномочия осуществляет руководитель Общества или замещающее его лицо.</p>
          <p className="text-v3 pb-2">Сотрудники или должностные лица Общества, оказывают содействие службе внутреннего контроля Общества в осуществлении ее функций в соответствии с общими требованиями и внутренними документами Общества.</p>
          <p className="text-v3 pb-2">Сотрудники или должностные лица Общества, которым стали известны факты нарушений законодательства Кыргызской Республики в сфере противодействия финансированию терроризма (экстремизма) и легализации (отмыванию) доходов, полученных преступным путем, допущенные сотрудниками Общества, при проведении операций (сделок), незамедлительно в письменном виде доводят эти факты до сведения своего непосредственного руководителя и службы внутреннего контроля Общества.</p>
          <p className="text-v3 pb-2">Общество обязано проводить на постоянной основе, не реже одного раза в год. оценку эффективности деятельности службы внутреннего контроля Общества, в порядке, установленном внутренними документами Общества.</p>
          <p className="text-v3 pb-2">• Порядок направления информации в уполномоченный государственный орган.</p>
          <p className="text-v3 pb-2">Общество обязано направлять в уполномоченный государственный орган сведения об операциях (сделках) подлежащих обязательному контролю, не позднее рабочего дня, следующего за днем совершения таких операций (сделок).</p>
          <p className="text-v3 pb-2">Общество обязано направлять в уполномоченный государственный орган сведения о подозрительных операциях (сделках), не позднее рабочего дня, следующего за днем признания таких операций (сделок).</p>
          <p className="text-v3 pb-2">Сведения предоставляются в уполномоченный государственный орган, согласно типовой форме, утвержденный уполномоченным государственным органом в соответствии с Законом Кыргызской Республики «О противодействии финансированию террористической деятельности и легализации (отмыванию) преступных доходов».</p>
          <p className="text-v3 pb-2">• Порядок обеспечения конфиденциальности информации.</p>
          <p className="text-v3 pb-2">Сотрудники и должностные лица Общества не вправе информировать клиентов о предоставлении информации в уполномоченный государственный орган.</p>
          <p className="text-v3 pb-2">Ограничение доступа лиц, непосредственно не связанных с процедурами сбора, обобщения, анализа, хранения и передачи информации об операциях (сделках) подлежащих обязательному контролю и о подозрительных операциях (сделках) в соответствии с настоящими Правилами, обеспечивается проведением следующих мероприятий:</p>
          <p className="text-v3 pb-2">• информация об операциях (сделках) подлежащих обязательному контролю и о подозрительных операциях (сделках), а также сведения, направленные Обществом в уполномоченный государственный орган, хранятся в электронном виде на специально защищенных от несанкционированного доступа электронных файлах на рабочем месте специального должностного лица;</p>
          <p className="text-v3 pb-2">• в документах и материалах на бумажных носителях, хранящихся в «делах» клиентов или иных делах Общества, запрещается делать какие-либо пометки о конкретных мерах, принимаемых в отношении конкретных операций (сделок), осуществляемых Обществом по поручению конкретных клиентов;</p>
          <p className="text-v3 pb-2">• все документы на бумажных носителях, а также информация на электронных дискетах, касающиеся деятельности специального должностного лица (отчеты, справки, предложения и т.п.), хранятся в металлическом шкафу (сейфе) отдельно от других документов.</p>
          <p className="text-v4 py-4">Глава 3. Заключительные положения</p>
          <p className="text-v3 pb-2">3.1.Перечень норм, изложенных в настоящих Правилах не может быть исчерпывающим и подлежит пересмотру/дополнениям/изменениям при возникновении новых/дополнительных требований законодательства, по мере изменения процессов в деятельности Общества и/или в целях эффективного противодействия легализации доходов, полученных от преступной деятельности и финансированию терроризма/экстремизма и/или распространения оружия массового уничтожения.</p>
          <p className="text-v3 pb-2">3.2.Контроль за исполнением Обществом настоящих Правил и организацией внутреннего контроля осуществляется уполномоченным государственным органом, в соответствии с их компетенцией и в порядке, установленном законодательством Кыргызской Республики.</p>
          <p className="text-v3 pb-2">3.3.В случае противоречия норм действующего законодательства Кыргызской Республики и настоящих Правил, то применяются нормы действующего законодательства Кыргызской Республики.</p>
          <p className="text-v3 pb-2">3.4.Лица, виновные в нарушении Правил, несут ответственность в соответствии с законодательством Кыргызской Республики.</p> */}
        </div>
      </div>
    </div>
  );
};

export default Terms;
