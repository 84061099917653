import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useParams } from "react-router"
import { BiSearch } from "react-icons/bi"
import img from "../../img/IMAGE.svg"
import lock from "../../img/lock-security-open-svgrepo-com.svg"
import axios from "axios"
import { url } from "../../api"
import Loading from "../../components/IU/loading/loading"

const PaymentServiceDetail = () => {
    const [list, setList] = useState([])
    const [value, setValue] = useState('')
    const { categoryId } = useParams()

    useEffect(() => {
        const headers = { Authorization: 'Bearer ' + localStorage.getItem('token') }
        axios
            .get(url + `/payment-services/list/${categoryId}`, { headers })
            .then((response) => {
                setList(response.data.list)
            })
            .catch((error) => { console.error("Error:", error) })
    }, [])

    return (
        <div id="payment_for">
            {list[0] ? (
                <div className="container">
                    <div className="holder">
                        <h1>{list && list[0].category.title}</h1>
                        <div>
                            <BiSearch className="search_i" />
                            <input
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                type="text"
                                placeholder="Поиск"
                            />
                        </div>
                    </div>
                    <div className="accounts">
                        {list
                            .filter((obj) => {
                                return obj.title.toLowerCase().includes(value.toLowerCase())
                            })
                            .map((el, id) => el.status == 2 ? (
                                <Link to={el.id} className="service-item" key={id}>
                                    <img src={el.logo ? el.logo : img} alt="" />
                                    <h1>{el.name}</h1>
                                </Link>
                            ) : (
                                <div className="service-item not-allow" key={id}>
                                    <img src={lock} alt={el.title} />
                                    <h1>{el.name}</h1>
                                </div>
                            ))}
                    </div>
                </div>
            ) : (
                <div className="loading_div">
                    <Loading />
                </div>
            )}
        </div>
    )
}

export default PaymentServiceDetail