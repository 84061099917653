import React from "react";
import "./Meet.css";
import coin from "../../img/slider1.svg";
import Union from "../../img/Union.svg";
import Subtract from "../../img/Subtract.svg";
import Unio from "../../img/Unio.svg";
import love from "../../img/love.svg";

const Meet = ({ color }) => {
  return (
    <div className="meet">
      <div className="container">
        <div className="save1">
          <div className="wrapper">
            <h1>
              <span
                className="meet_green"
                style={{ color: color ? "#4ac49e" : "#ff5c00" }}
              >
                Xbt.kg - надежный
              </span>{" "}
              сервис для обмена криптовалюты!
            </h1>
            <p>
              XBT.kg —{" "}
              <span className="spa">
                это сервис отвечающий даже самым серьезным запросам! XBT не
                просто обменка, а самое главное дело в нашей жизни, нам
                действительно важен каждый наш клиент.{" "}
              </span>
            </p>
          </div>

          <div className="about-image-block">
            <img
              className="image"
              src={coin}
              alt="XBT LLC - About image"
              width="350px"
            />
          </div>
        </div>
        <div className="clear">
          <div className="abouts">
            <div className="loke">
              <img src={Union} alt="" />
            </div>
            <label>
              Нам доверяют более <br /> <span style={{color:"#73F4AD"}}>70 млн </span> пользователей
            </label>
          </div>
          <div className="abouts">
            <div className="loke">
              <img src={Subtract} alt="" />
            </div>
            <label>
              Гарантия безопасности <br /> ваших данных
            </label>
          </div>
          <div className="abouts">
            <div className="loke">
              <img src={Unio} alt="" />
            </div>
            <label>
              Защита вашего <br />
              виртуального кошелька
            </label>
          </div>
          <div className="abouts">
            <div className="loke">
              <img src={love} alt="" />
            </div>
            <label>
              Удобное <br />
              использование
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Meet;
