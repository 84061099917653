import React from "react";
import "./OurAdvantages.css";
import lok from "./../../img/lok.svg";
import ic_commission from "./../../img/ic_commission.svg";
import ic_license from "./../../img/ic_license.svg";
import ic_oplata from "./../../img/ic_oplata.svg";
export default function OurAdvantages() {
  return (
    <div className="OurAdvantages">
      <div style={{ textAlign: "center" }}>
        <h2>Наши преимущества</h2>
        <label>
          Наше видение заключается в продвижении свободы денег. Мы верим, что,
          продвигая эту свободу, мы можем повлиять на качество жизни по
          всему миру.
        </label>
      </div>
      <div className="advantage_wrar">
        <div className="advantage-wrapper">
          <div className="lok">
            <img style={{ width: "30%" }} src={lok} alt="" />
          </div>
          <label>
            Безопасность <br /> пользователей
          </label>
        </div>
        <div className="advantage-wrapper">
          <div className="lok">
            <img style={{ width: "30%" }} src={ic_commission} alt="" />
          </div>
          <label>
            Прозрачные <br /> комиссии
          </label>
        </div>
        <div className="advantage-wrapper">
          <div className="lok">
            <img style={{ width: "30%" }} src={ic_license} alt="" />
          </div>
          <label>
            Наличие <br /> лицензии
          </label>
        </div>
        <div className="advantage-wrapper">
          <div className="lok">
            <img style={{ width: "30%" }} src={ic_oplata} alt="" />
          </div>
          <label>
            Разнообразие <br /> способов оплаты
          </label>
        </div>
      </div>
    </div>
  );
}
