import React, { useState, useEffect } from "react";
import "./Popular.css";
import { Line, LineChart } from "recharts";
import { NavLink, useNavigate } from "react-router-dom";
import Loading from "../IU/loading/loading";
import { Alert } from "../IU/alert/alert";
import Vector from "./../.././img/Vector1.svg";
import Vector2 from "./../.././img/Vector2.svg";

const chart = [
  { name: "2017", react: 32, angular: 27, vue: 60 },
  { name: "2018", react: 56, angular: 34, vue: 54 },
  { name: "2019", react: 45, angular: 41, vue: 54 },
  { name: "2020", react: 60, angular: 42, vue: 28 },
  { name: "2021", react: 51, angular: 31, vue: 27 },
  { name: "2022", react: 90, angular: 48, vue: 49 },
];
const Popular = ({ color, data, datas }) => {
  const navigate = useNavigate();
  const [dataFromLocalStorage, setDataFromLocalStorage] = useState(false);
  const [ratesArray, setRatesArray] = useState([]);

  useEffect(() => {
    const ratesArray = datas.map((el, index) => ({
      price: el.rates[index]?.price || index,
    }));
    setRatesArray(ratesArray);
  }, [datas]);

  useEffect(() => {
    const emailFromLocalStorage = localStorage.getItem("data_register");
    setDataFromLocalStorage(!!emailFromLocalStorage);
  }, []);

  const handleStartTrading = () => {
    if (!dataFromLocalStorage) {
      navigate("/register-personal");
      Alert("warning", "вы не авторизован");
    } else {
      navigate("/dashboard/buy-cryptocurrency");
    }
  };

  return (
    <div id="popular">
      <div className="popular">
        <div className="blr"></div>
        <div className="container">
          <h1>Популярные криптовалюты</h1>
          <div className="wrapper">
            <div className="boxs">
              <p>Торговая пара</p>
              <p>Цена</p>
              <p>Обьем 24ч (USDT)</p>
              <p>Рынок</p>
              <p className="do">Действие</p>
              <div></div>
            </div>
            <div className="secend">
              <p>Торговая пара</p>
              <p>Цена</p>
            </div>
            {data.map((el, i, key) => (
              <div key={i}>
                <div className="box first">
                  <div className="logo_div">
                    <img src={el.logo} alt="" />
                    <p className="title">{el.name}</p>
                  </div>
                  <p>{el.rate}</p>
                  <p
                    style={{
                      color: el.difference.includes("-") ? "red" : "#30E0A1",
                    }}
                  >
                    {el.difference}
                  </p>
                  {el.difference.includes("-") ? (
                    <img src={Vector2} alt="" />
                  ) : (
                    <img src={Vector} alt="" />
                  )}
                  <div style={{ display: "flex", gap: "10px" }}>
                    {" "}
                    <button
                      onClick={() =>
                        navigate(`/dashboard/buy-cryptocurrency/${el.currency}`)
                      }
                      style={{
                        color: color ? "#fff" : "#fff",
                        border: color
                          ? "1px solid #8D6AE2"
                          : "1px solid #8D6AE2",
                      }}
                      className="trade"
                    >
                      {" "}
                      Покупка{" "}
                    </button>
                    <button
                      onClick={() =>
                        navigate(`/dashboard/buy-cryptocurrency/${el.currency}`)
                      }
                      style={{
                        color: color ? "#fff" : "#fff",
                        border: color
                          ? "1px solid #73F4AD                        "
                          : "1px solid #73F4AD",
                      }}
                      className="trade"
                    >
                      {" "}
                      Продажа{" "}
                    </button>
                  </div>
                </div>
                <div className="box secend">
                  <div className="logo_div">
                    <img src={el.logo} alt="" />
                    <p className="title">{el.currency}</p>
                  </div>
                  <p>{el.rate}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popular;
