import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./Stay.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../api";

const Stay = ({ color }) => {
  const navigate = useNavigate();
  const [stay, setStay] = useState([]);
  const stayData = stay ? stay : [];
  const stays = Object.values(stayData).map((data) => data);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url + "/index");
        setStay(response.data.news);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1284,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="stay">
      <div className="container">
        <h1 className="skey_h1">Оставайтесь в курсе последних новостей</h1>
        <div className="news-all">
          <div className="news_foto" style={{width:"50%"}}>
            <h2>Последние новости</h2>
            <p>
              Наше видение заключается в продвижении свободы денег. Мы верим,
              что, продвигая эту свободу, мы можем повлиять на качество жизни по
              всему миру.
            </p>
            <button onClick={() => navigate("/News")} className="btn">
              Смотреть все
            </button>
          </div>
          {stay ? (
            <div className="scroll">
              {" "}
              {stays.map((el, id) => (
                <div
                  key={id}
                  className="width"
                  onClick={() => navigate("/news/" + el.id)}
                >
                  <div key={id} className="skey_block">
                    <div className="news-photo">
                      <img className="photo" src={el.photo} alt="" />
                    </div>
                    <div style={{ padding: "0 20px 0 20px" }}>
                      <h4 color="#fff">{el.name} </h4>
                      <p >{el.date}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="not_data">
              <h1 style={{ color: color ? "var(--green)" : "var(--orange)" }}>
                Новостей нет
              </h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stay;
