import React, { useEffect, useState, useMemo } from "react";
import "../BuyCryptocurrency/BuyCryptocurrency.css";
import axios from "axios";
import { url } from "../../api";

const Rates = () => {
    const [currencies, setCurrencies] = useState([])
    const [fiat, setFiat] = useState([])

    useEffect(() => {
        axios
            .get(url + `/currencies`)
            .then((response) => {
                // console.log(response.data.currencies)
                setCurrencies(Object.values(response.data.currencies).map((data) => data))
            })
    }, []);

    useEffect(() => {
        axios
            .get(url + `/currencies/fiat`)
            .then((response) => {
                // console.log(response.data.currencies)
                setFiat(Object.values(response.data.currencies).map((data) => data))
            })
    }, []);

    return (
        <div id="sell_cryptocurrency">
            <div className="containerrr">
                <div className="holder">
                    <h1>Курс валют</h1>
                </div>
                <div className="border-class" style={{ marginBottom: '20px' }}>
                    {fiat.map((el, i) => (
                        <div key={i}>
                            {el.rate !== null ? (
                                <>
                                    <div className="buy_cryp">
                                        <div className="with_bys" style={{ margin: "0 0 0 5px" }}>
                                            <div className="big">
                                                <div style={{ textAlign: "start" }}>
                                                    <h3>USDT/{el.currency_t}</h3>
                                                    <h6>Продажа</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div></div>
                                        <div>
                                            <h2 style={{ color: "#fff" }} className="мarket_h2">1.00 USDT = {el.rate_buy} {el.currency_t}</h2>
                                            <h3 className="мarket_h3">Курс</h3>
                                        </div>
                                    </div>
                                    <div className="buy_cryp">
                                        <div className="with_bys" style={{ margin: "0 0 0 5px" }}>
                                            <div className="big">
                                                <div style={{ textAlign: "start" }}>
                                                    <h3>USDT/{el.currency_t}</h3>
                                                    <h6>Покупка</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div></div>
                                        <div>
                                            <h2 style={{ color: "#fff" }} className="мarket_h2">1.00 USDT = {el.rate_sell} {el.currency_t}</h2>
                                            <h3 className="мarket_h3">Курс</h3>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    ))}
                </div>
                <div className="border-class" style={{ marginBottom: '40px' }}>
                    {currencies.map((el, id) => (
                        <div className="buy_cryp" id={id}>
                            <div className="with_bys" style={{ margin: "0 0 0 5px" }}>
                                <div className="big">
                                    <img src={el.logo} alt="" />
                                    <div style={{ textAlign: "start" }}>
                                        <h3>{el.currency}</h3>
                                        <h6>{el.name}</h6>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                            <div>
                                <h2
                                    style={{
                                        color: el.difference.includes("-") ? "red" : "#30E0A1",
                                    }}
                                    className="мarket_h2"
                                >
                                    {"$" + el.rate}
                                </h2>
                                <h3 className="мarket_h3">Цена</h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Rates;