import React, { useEffect, useState } from "react";
import "./Blocks.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Vector from "./../.././img/Vector1.svg";
import Vector2 from "./../.././img/Vector2.svg";

const Blocks = ({ data, datas }) => {
  const [ratesArray, setRatesArray] = useState([]);
  useEffect(() => {
    const ratesArray = datas.map((el, index) => ({
      price: el.rates[index]?.price || index,
    }));
    setRatesArray(ratesArray);
  }, [datas]);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1284,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 824,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="blocks_comp">
      <div className="width">
        <Slider {...settings} className="slider_block">
          {data.map((el, index) => (
            <div key={index} className="home_block">
              <div className="box_block_block">
                <div className="absolune">
                  <div className="logo-name">
                    <img className="logo_img" src={el.logo} alt="" />
                    <p className="i">{el.name}</p>
                  </div>
                  <h1>
                    <span className="dollar">$</span> {el.rate}
                  </h1>
                  <div className="diference">
                    {el.difference.includes("-") ? (
                      <img style={{ width: "20px" }} src={Vector2} alt="" />
                    ) : (
                      <img style={{ width: "20px" }} src={Vector} alt="" />
                    )}
                    <p
                      style={{
                        color: el.difference.includes("-") ? "red" : "#30E0A1",
                      }}
                    >
                      {el.difference}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Blocks;
