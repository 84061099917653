import React, { useEffect, useState } from "react";
import axios from "axios";
import { url } from "../../api";
import "./PrivacyAll.css";

const PrivacyPolicy = () => {
  const [content, setContent] = useState('')

  useEffect(() => {
    axios
      .get(url + `/pages/privacy-policy`)
      .then((response) => {
        if(response.data.detail.content) {
          setContent(response.data.detail.content)
        }
      })
  }, []);
  
  return (
    <div className="container">
      <div className="privacy-wrap" dangerouslySetInnerHTML={{__html: (content) ? content : ''}}>
        {/* <h1 className="text-v1 text-center">Политика конфиденциальности</h1>
        <p className="text-v4 py-4">
          Правила оператора обмена виртуальных активов
        </p>
        <p className="text-v3">
          Настоящие правила разработаны Обществом с ограниченной
          ответственностью «ОсОО «Кейджи Блокчейн Солушинс»» (далее по тексту -
          Общество) в соответствии с Законом «О виртуальных активах»,
          Постановлением Кабинета Министров Кыргызской Республики «О вопросах
          регулирования отношений, возникающих при обороте виртуальных активах»,
          Положением «О деятельности операторов обмена виртуальных активов и
          ведение Реестра операторов обмена виртуальных активов» и иными
          нормативными правовыми актами Кыргызской Республики.
        </p>
        <p className="text-v3">
          Целью настоящих Правил является регулирование отношений, возникающих
          между Обществом и клиентами.
        </p>
        <p className="text-v3">
          Действие настоящих Правил распространяется на всех сотрудников и
          клиентов Общества.
        </p>
        <p className="text-v3">
          Изменения и дополнения к настоящим Правилам разрабатываются и
          утверждаются Обществом.
        </p>
        <p className="text-v4 py-4">
          1. Порядок установления отношений c клиентами.
        </p>
        <p className="text-v3">
          Общество вправе устанавливать отношения с клиентами, в которые могут
          выступать любые физические и юридические лица (как резиденты, так и
          нерезиденты Кыргызской Республики), за исключением случаев,
          предусмотренных законодательством Кыргызской Республики, в сфере
          противодействия финансированию террористической деятельности и
          легализации (отмыванию) преступных доходов.
        </p>
        <p className="text-v3">
          Для установления отношений с клиентом сотрудник Общества запрашивает у
          него следующую информацию:
        </p>
        <p className="text-v3">
          у физического лица - фамилию, имя, отчество (если таковое имеется),
          дату рождения, адрес места жительства, согласно паспорту или
          документу, удостоверяющего личность;
          {">"} у юридического лица - наименование, место нахождения,
          идентификационный номер плательщика, согласно свидетельству о
          государственной регистрации юридического лица;
          <br />
          {">"} контактные данные клиента, позволяющие установить с ним связь
          (адрес электронной почты и при необходимости иные контактные данные);
          <br />
          {">"} сведения, предусмотренные локальными актами или договорными
          обязательствами Общества, в том числе в сфере предотвращения
          легализации доходов, полученных преступным путем, финансирования
          террористической деятельности и финансирования распространения оружия
          массового поражения.
        </p>
        <p className="text-v3">
          В случае, если Общество предоставляет клиентам возможность создания
          электронного личного кабинета на своей платформе, Общество обязано
          принимать меры по исключению создания одним и тем же клиентом двух и
          более электронных личных кабинетов.
        </p>
        <p className="text-v4 py-4">
          Общество не вправе устанавливать отношения с:
        </p>
        <p className="text-v3">
          {">"} физическими лицами, не достигшими 18 лет;
          <br />
          {">"} физическими лицами, индивидуальными предпринимателями,
          юридическими лицами, а также юридическими лицами, бенефициарные
          владельцы, которых включены в санкционный перечень, определенный
          законодательством в сфере противодействия финансированию
          террористической деятельности и легализации (отмыванию) преступных
          доходов, а также противодействия финансированию экстремистской
          деятельности и финансированию распространения оружия массового
          уничтожения;
          <br />
          {">"} иными лицами, предусмотренными локальными актами Общества.
          <br />
        </p>
        <p className="text-v3">
          Сотрудник Общества обязан обеспечить хранение документов по
          идентификации/верификации клиента (анкета клиента), договора, а если
          клиент юридическое лицо или филиал/представительство, учредительных
          документов, свидетельства о регистрации/перерегистрации юридического
          лица и филиалов/представительств, а также документов, полуденных в
          ходе определения уровня знаний (компетентности), не менее 5
          календарных лет с даты получения таких документов.
        </p>
        <p className="text-v4 py-4">Сотрудник Общества обязан:</p>
        <p className="text-v3">
          {">"} - производить звукозапись (видеозапись) переговоров с клиентами,
          осуществляемых с использованием средств связи (с обязательным
          предварительным уведомлением об этом клиента);
          <br />
          {">"} хранить данные звукозаписи (видеозаписи) и всю переписку с
          клиентами в течение не менее 5 лет с даты осуществления
          (возникновения) таких звукозаписей (видеозаписей) и переписки;
          <br />
          {">"} осуществлять резервное копирование всех данных о совершенных
          клиентами сделках (операциях) по завершении каждого рабочего дня;
          <br />
          {">"} обеспечивать незамедлительное исполнение заявок клиентов на
          покупку и (или) продажу виртуальных активов за деньги или электронные
          деньги либо обмен виртуальных активов одного вида на виртуальные
          активы другого вида (при наличии возможности исполнить такие заявки);
          <br />
          {">"} принимать меры по обеспечению знания сотрудниками Общества и
          лицами, привлеченными им по гражданско-правовым договорам, которые
          непосредственно вовлечены в осуществление деятельности Общества,
          законодательством в сфере оборота виртуальных активов;
          <br />
          {">"} выполнять другие требования, предусмотренные Законом Кыргызской
          Республики «О виртуальных активах», другими нормативными правовыми
          актами, а также локальными актами Общества.
          <br />
          {">"} Общество обязано обеспечить сообщение клиентам информации, при
          этом внимание клиента должно быть привлечено к сроку исполнения заявок
          клиентов на покупку и (или) продажу виртуальных активов за деньги или
          электронные деньги либо обмен виртуальных активов одного вида на
          виртуальные активы другого вида, а также порядку изменения и
          прекращения договора Обществом в одностороннем порядке (если такой
          порядок предусмотрен в договоре). Сотрудник Общества до совершения с
          ним соответствующей сделки обязан довести до сведения клиента:
          <br />
          {">"} о рисках, которым подвергаются лица, приобретающие виртуальные
          активы, с указанием на то, что виртуальные активы не являются
          средством платежа, не обеспечиваются государством и приобретение
          виртуальных активов может привести к полной потере денежных средств и
          иных объектов гражданских прав (инвестиций), переданных в обмен на
          виртуальные активы (в том числе в результате волатильности стоимости
          виртуальных активов, технических сбоев (ошибок), совершения
          противоправных действий, включая хищение);
          <br />
          {">"} о том, что объектом сделки, планируемой к совершению Обществом,
          выступают обеспеченные или необеспеченные виртуальные активы, которые
          были размещены (не в части эмиссии) на его платформе.
        </p>
        <p className="text-v3">
          Информация, которая должна быть доведена каждому клиенту в отдельности
          (с получением сотрудником Общества подтверждения факта ознакомления
          клиента с ней) должна быть в доступной для клиента форме (текст должен
          быть четким и легко воспринимаемым, в том числе быть представленным в
          логически структурированном виде и без использования в нем сложных для
          восприятия словесных конструкций, совершенным легко читаемым размером
          шрифта).
        </p>
        <p className="text-v3">
          Посредством ее изложения текстом, исключающим различное его толкование
          (должны быть даны понятные определения) до установления отношений с
          клиентом независимо от того, сообщалась ли такая информация в рекламе
          деятельности Общества.
        </p>
        <p className="text-v4 py-4">
          2. Порядок совершения Обществом сделок с виртуальными активами.
        </p>
        <p className="text-v3">
          Общество совершает следующие сделки с виртуальными активами:
        </p>
        <p className="text-v3">
          {">"} покупка виртуальных активов за национальную валюту, иностранную
          валюту, электронные деньги, в том числе международные электронные
          деньги;
          {">"} продажа виртуальных активов за национальную валюту, иностранную
          валюту, электронные деньги, в том числе международные электронные
          деньги;
          {">"} обмен виртуальных активов одного вида на виртуальные активы
          другого вида.
        </p>
        <p className="text-v3">
          Сделки с виртуальными активами совершаются Обществом от собственного
          имени и в своих интересах с использованием специализированной
          платформы, (информационных систем и (или) программно-технических
          комплексов, работающих в режиме самообслуживания).
        </p>
        <p className="text-v3">
          Для совершения сделки с виртуальными активами Общество получает от
          клиентов заявки на покупку и (или) продажу виртуальных активов за
          деньги или электронные деньги либо обмен виртуальных активов одного
          вида на виртуальные активы другого вида, которые являются офертами.
        </p>
        <p className="text-v3">
          Заявка должна соответствовать законодательству Кыргызской Республики и
          содержание заявки определяются Приложением 2,3,4 к настоящим правилам.
        </p>
        <p className="text-v3">
          Исполнение названной заявки должно производиться незамедлительно после
          ее получения и по той цене, которая была сообщена клиенту в момент
          направления клиентом соответствующей заявки. Общество обязано
          обеспечить фиксацию времени наступления данного момента с точностью до
          секунд и отправление клиенту электронного сообщения,
          предусматривающего это время, незамедлительно после получения от
          клиента соответствующей заявки.
        </p>
        <p className="text-v3">
          При использовании в своей деятельности специализированной платформы
          программно-технических комплексов, работающих в режиме
          самообслуживания, сотрудник Общества обязан принять меры по
          обеспечению их сохранности, в том числе обеспечить наличие в местах их
          нахождения видеонаблюдения, а также заключить направленный на
          круглосуточную охрану данных комплексов Договор об оказании охраны
          уполномоченным органом в сфере по обеспечению общественного порядка и
          безопасности.
        </p>
        <p className="text-v4 py-4">
          3. Сервис предоставляется ОсОО "Кейджи блокчейн солушенс"
        </p>
        <p className="text-v3">
          ИНН 01105202310063 г. Бишкек, микрорайон № 10, Дом 29/1. Лицензия №46
          от 23 августа 2023 года выдана Государственной службой регулирования и
          надзора за финансовым рынком при Министерстве экономики и финансов
          Кыргызской Республики
        </p> */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;
