import React from "react";
import "./Cabinet.css";
import Slider from "react-slick";
import { MdEmail, MdOutlineDateRange } from "react-icons/md";
import { AiFillSafetyCertificate } from "react-icons/ai";
import Loading from "../../components/IU/loading/loading";
import { NavLink, useNavigate } from "react-router-dom";

const Cabinet = ({ color, datas, loading, datas_personal, datas_log }) => {
  const navigate = useNavigate();
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1224,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="cabinet">
      <div>
        <div className="lina"></div>
        {loading ? (
          <div className="loading_div">
            <Loading />
          </div>
        ) : (
          <div className="contewainer">
            <p className="h1 p">Ваши кошельки</p>

            <div className="wrapper_history">
              <div className="wrapper">
                {datas ? (
                  datas.map((el) => (
                    <div className="padd" key={el.id}>
                      <div className="box">
                        <div className="save">
                          <div className="content">
                            <img src={el.logo} alt="" />
                            <div className="h1">
                              <h1>{el.currency}</h1>
                              <p>{el.name}</p>
                            </div>
                          </div>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <h1>{el.balance}</h1>
                            <h1
                              style={{
                                color:
                                  el.converted_balance > 0 ? "#0F8F67" : "red",
                              }}
                            >
                              ${el.converted_balance}
                            </h1>
                          </div>
                          <div
                            className="btns btnses"
                            style={{ width: "50%", flexDirection: "column" }}
                          >
                            <button
                              disabled={el.can_deposit ? false : true}
                              onClick={() =>
                                navigate(`/dashboard/top-up/${el.currency}`)
                              }
                              className="commiss"
                            >
                              Ввод
                            </button>
                            <button
                              disabled={el.can_withdraw ? false : true}
                              onClick={() =>
                                navigate(
                                  `/dashboard/translation/${el.currency}`
                                )
                              }
                              className="conclusion"
                            >
                              Вывод
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h1>данных нет</h1>
                )}
              </div>
              <div className="wrapper_accaunt">
                {datas_personal[0] ? (
                  <div className="save_box_two">
                    <h1 style={{ marginBottom: "12px" }}>
                      Лимиты за текущий день
                    </h1>
                    <div
                      className="replenishment"
                      style={{ paddingBottom: "5px" }}
                    >
                      <p>Ввод</p>
                      <p>Продажа</p>
                      <p>Покупка</p>
                    </div>
                    <div className="replenishment info">
                      <p>
                        {datas_personal[0].limits.refill} <small>USDT</small>
                      </p>
                      <p>
                        {datas_personal[0].limits.sell} <small>USDT</small>
                      </p>
                      <p>
                        {datas_personal[0].limits.buy} <small>USDT</small>
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="save_box three">
                  <h1 className="title-v1">Персональные данные</h1>
                  {loading
                    ? ""
                    : datas_personal.map((el, id) => (
                        <div key={id} className="content_three">
                          <div className="content_box">
                            <MdEmail size={20} color={"#8D6AE2"} />{" "}
                            <div>
                              <p className="text">Дата регистрации</p>
                              <p>{el.date_register}</p>
                            </div>
                          </div>
                          <div className="content_box">
                            <MdOutlineDateRange size={20} color={"#8D6AE2"} />
                            <div>
                              <p className="text">Последний вход</p>
                              <p>{el.last_access}</p>
                            </div>
                          </div>
                          <div className="content_box">
                            <AiFillSafetyCertificate
                              size={22}
                              color={"#8D6AE2"}
                            />{" "}
                            <div>
                              <p className="text">Электронная почта</p>
                              <p>{el.email}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Cabinet;
