import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { url } from '../../api'
import { Alert } from "../../components/IU/alert/alert"
import { useNavigate, useParams } from "react-router-dom"
import Loading from "../../components/IU/loading/loading"
import '../Translation/Translation.css'

const PayService = ({ currencies, mainBalance }) => {
    const navigate = useNavigate()
    const { serviceId } = useParams()
    const [code, setCode] = useState('')
    const [amount, setAmount] = useState('')
    const [quantity, setQuantity] = useState('')
    const [requisite, setRequisite] = useState('')
    const [loading, setLoading] = useState(false)
    const [modal2fa, setModal2fa] = useState(false)
    const [modalConfirm, setModalConfirm] = useState(false)
    const [detail, setServiceDetail] = useState([])
    const [confirm, setConfirmDetail] = useState([])
    const balance = currencies.filter((obj) => { return obj.currency.includes('USDT') }).map((el) => el)
    const headers = { Authorization: 'Bearer ' + localStorage.getItem('token') }

    useEffect(() => {
        axios
            .get(url + `/profile/personal`, { headers })
            .then((response) => {
                if (response.data.profile.verification.value !== '2') {
                    Alert('error', 'Для осуществления данной операции, необходимо пройти проверку KYC')
                    navigate("/dashboard/settings?tab=KYC")
                }
            })
            .catch((error) => {
                Alert('error', error.response.data.messages);
                navigate("/dashboard/payment-services")
            })
    }, [])

    useEffect(() => {
        axios
            .get(url + `/payment-services/detail/${serviceId}`, { headers })
            .then((response) => {
                if (response.data.detail.status == 2) {
                    setServiceDetail(response.data.detail)
                } else {
                    navigate('/dashboard/payment-services')
                }
            })
            .catch((error) => {
                Alert('error', error.response.data.messages)
                navigate('/dashboard/payment-services')
            });
    }, [])

    function numberFormat(number, float) {
        var string, result
        if (String(number).includes('.')) {
            string = String(number).split('.')
            result = (float > 0) ? string[0] + '.' + string[1].substring(0, float) : string[0]
        } else {
            result = number
        }
        return parseFloat(result)
    }

    function calculate(amount) {
        if (amount > 0) {
            const quantity = amount * detail?.rate
            const commission = (quantity * parseFloat(detail.commission)) / 100
            const result = (quantity - commission) - parseFloat(detail.addination_commission)
            setQuantity(numberFormat(result >= 0 ? result : 0, 2))
        } else {
            setQuantity(0)
        }
        setAmount(numberFormat(amount, 2))
    }

    function closeModal() {
        setLoading(false)
        setModal2fa(false)
        setModalConfirm(false)
    }

    // async function createPayment(e) {
    //     e.preventDefault()
    //     let status
    //     if (parseFloat(amount) > parseFloat(balance[0].balance)) {
    //         status = false
    //         Alert('error', 'Недостаточно баланса на счете.')
    //     }
    //     if (detail && quantity < detail.min_sum) {
    //         status = false
    //         Alert('error', 'Минимальная сумма ' + detail.min_sum)
    //     }
    //     if (detail && quantity > detail.max_sum) {
    //         status = false
    //         Alert('error', 'Максимальная сумма ' + detail.max_sum)
    //     }
    //     if (detail && detail.regex) {
    //         const regex = new RegExp(detail.regex, 'g')
    //         if (!regex.test(requisite)) {
    //             status = false
    //             Alert('error', 'Неверный реквизит платежного документа')
    //         }
    //     }
    //     if (status !== false) {
    //         const formData = new FormData()
    //         formData.append('serviceId', serviceId)
    //         formData.append('amount', amount)
    //         formData.append('requisite', requisite)
    //         setLoading(true)
    //         try {
    //             const response = await axios.post(url + '/payment-services/create', formData, { headers })
    //             if (response.data.response === true) {
    //                 setConfirmDetail(response.data.confirm)
    //                 setModalConfirm(true)
    //             } else {
    //                 Alert('error', response.data.messages)
    //             }
    //             setLoading(false)
    //         } catch (error) {
    //             Alert('error', error.response.data.messages)
    //             setLoading(false)
    //         }
    //     }
    // }

    async function confirmPayment() {
        if (modal2fa && code.length !== 6) {
            Alert('error', 'Введите код')
        } else {
            setLoading(true)
            await axios
                .post(url + `/payment-services/confirm/${confirm.id}`, { '2fa_otp': code }, { headers })
                .then((response) => {
                    if (response && response.data.response === true) {
                        mainBalance()
                        Alert('success', (response.data.messages ? response.data.messages : 'Успешно'))
                        navigate('/dashboard/operations')
                    }
                })
                .catch((error) => {
                    if (error.response.data.security === '2fa') {
                        closeModal()
                        setModal2fa(true)
                    }
                    Alert('error', error.response.data.messages)
                    setLoading(false)
                })
        }
    }

    function getFields(fields) {
        return JSON.parse(JSON.stringify(fields))
    }

    function getCurrency(currency) {
        return currency.split('=')
    }

    const formSubmit = async (event) => {
        event.preventDefault()
        var formData = new FormData(event.target)
        let formObject = Object.fromEntries(formData.entries())
        let status
        if (parseFloat(formObject.amount) > parseFloat(balance[0].balance)) {
            status = false
            Alert('error', 'Недостаточно баланса на счете.')
        }
        if (detail && quantity < detail.min_sum) {
            status = false
            Alert('error', 'Минимальная сумма ' + detail.min_sum)
        }
        if (detail && quantity > detail.max_sum) {
            status = false
            Alert('error', 'Максимальная сумма ' + detail.max_sum)
        }
        if (detail && detail.regex && formObject.requisite) {
            const regex = new RegExp(detail.regex, 'g')
            if (!regex.test(formObject.requisite)) {
                status = false
                Alert('error', 'Неверный реквизит платежного документа')
            }
        }
        if (status !== false) {
            formData.append('serviceId', serviceId)
            setLoading(true)
            try {
                const response = await axios.post(url + '/payment-services/create', formData, { headers })
                if (response.data.response === true) {
                    setConfirmDetail(response.data.confirm)
                    setModalConfirm(true)
                } else {
                    Alert('error', response.data.messages)
                }
                setLoading(false)
            } catch (error) {
                Alert('error', error.response.data.messages)
                setLoading(false)
            }
        }
    }

    return (
        <div id="translation">
            <div className="container-custom">
                <div className="holder pb-3">
                    <h1>{detail.category && detail.category.title} - {detail.name} {detail.title && (`(${detail.title})`)}</h1>
                </div>
                <div className="tether">
                    <div className="user_usdt">
                        <div className="create">
                            <div className="create_capital">
                                <h3>Минимальная сумма</h3>
                                <h2>{detail && detail.min_sum} <u>C</u></h2>
                            </div>
                            <div className="create_capital">
                                <h3>Максимальная сумма</h3>
                                <h2>{detail && detail.max_sum} <u>C</u></h2>
                            </div>
                        </div>
                        <div className="create one_cre">
                            <div className="create_capital mat">
                                <h3>Комиссия</h3>
                                <h2>{detail && detail.commission}% {detail.addination_commission > 0 && (`+ ${detail.addination_commission} KGS`)}</h2>
                            </div>
                            <div className="create_capital">
                                <h3>Курс</h3>
                                {detail && (<h2>1.00 USDT = {detail?.rate} KGS</h2>)}
                            </div>
                        </div>
                        <form onSubmit={formSubmit}>
                            <div className="flex-balanse">
                                <label>Сумма к списания (USDT)</label>
                                <p className="balans-crypty">{(balance[0]) && balance[0].balance + ' ' + balance[0].currency}</p>
                            </div>
                            <div className="position-lation">
                                <input
                                    type="number"
                                    name="amount"
                                    value={amount}
                                    onChange={(e) => calculate(e.target.value)}
                                    placeholder="Сумма отправления"
                                    step=".01"
                                    required
                                />
                                <div className="max-balanse" onClick={() => calculate((balance[0].balance) ? balance[0].balance : 0)}>
                                    <p className="balans-crypty">MAX</p>
                                </div>
                            </div>
                            <label>Cумма к зачислению (KGS)</label>
                            <input
                                className="not_allowed"
                                defaultValue={quantity}
                                type="text"
                                placeholder="Cумма к получению"
                                readOnly
                            />
                            {detail.fields !== null ? (
                                <>
                                    {detail.fields?.map((el, i) => (
                                        <div key={i}>
                                            {el.Dictionary != '' ? (
                                                <>
                                                    <label>{el.Name}</label>
                                                    <select name={`fields[${el.Field}]`} className="form-select">
                                                        {el.Dictionary.split(';').map((currency, k) => (
                                                            <option value={getCurrency(currency)[0]} key={k}>{getCurrency(currency)[1]}</option>
                                                        ))}
                                                    </select>
                                                </>
                                            ) : (
                                                <>
                                                    <label>{el.Name}</label>
                                                    <input type="text" name={`fields[${el.Field}]`} placeholder={el.Name} pattern={el.Regex || null} required />
                                                </>
                                            )}
                                        </div>
                                    ))}
                                </>
                            ) : (
                                <>
                                    <label>Введите реквизит</label>
                                    <input
                                        type="text"
                                        name="requisite"
                                        placeholder={detail && detail.description ? detail.description : 'Введите реквизит'}
                                        required
                                    />
                                </>
                            )}
                            <button type="submit" className="btn" disabled={loading}>
                                {loading ? <Loading /> : "Продолжить"}
                            </button>
                        </form>
                    </div>
                    <div className="noticw_div">
                        <div className="notice">
                            <h1>Уведомление об выводе</h1>
                            <p className="pro">
                                Наслаждайтесь нулевой комиссией и быстрой скоростью вывода
                                средств на кастодиальный кошелек.
                            </p>
                            <p className="pro">
                                В настоящее время обрабатывает все связанные с этим деловые
                                вопросы в соответствии с последними правилами соответствия,
                                запущенными корейскими платформами. Пожалуйста, не выводите
                                средства на платформы Upbit или Coinone до завершения
                                процесса, иначе вы можете потерять свои активы.
                            </p>
                            <p className="coin">
                                Пожалуйста, не отправляйте средства на адрес ICO или для
                                краудфандинга. Мы не несем ответственности за распространение
                                любых будущих токенов, которые вы можете получить.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {modalConfirm && (
                <div className="modal">
                    <div onClick={() => closeModal()} className="not_confirm"></div>
                    <div className="save_form">
                        <h1>Подтвердите действие</h1>
                        <div className="box_form">
                            <p>Сумма к списанию</p>
                            <p className="form">{confirm.credit} USDT</p>
                        </div>
                        <div className="box_form">
                            <p>Сумма к зачислению</p>
                            <p className="form">{confirm.amount} KGS</p>
                        </div>
                        <div className="box_form">
                            <p>Комиссия</p>
                            <p className="form">{confirm.commission} KGS</p>
                        </div>
                        <div className="box_form">
                            <p className="form">{confirm.description}</p>
                        </div>
                        <button disabled={loading} onClick={() => confirmPayment(confirm.id)} className="btn_confirm">
                            {loading ? <Loading /> : "Подтвердить"}
                        </button>
                    </div>
                </div>
            )}
            {modal2fa && (
                <div className="modal-window-v2" onClick={() => closeModal()}>
                    <div className="modal-window-v2-wrap" onClick={(e) => e.stopPropagation()}>
                        <h2>Двухфакторная аутентификация</h2>
                        <p>Зайдите в свой аунтефикатор и введите полученный код</p>
                        <div className="form">
                            <input
                                type="number"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                placeholder="Введите защитный код *"
                                required
                                autoFocus
                            />
                            <button type="button" disabled={loading} onClick={() => confirmPayment(confirm.id)} className="btn_confirm">
                                {loading ? <Loading /> : "Подтвердить"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PayService