import React, { useEffect, useState } from "react";
import "./Head.css";
import { MdAccountBalanceWallet } from "react-icons/md";
import { RxExit } from "react-icons/rx";
import { NavLink } from "react-router-dom";
import { AiFillHome, AiOutlineLock } from "react-icons/ai";
import { FaWallet, FaShoppingBag } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { BsFillCreditCardFill } from "react-icons/bs";
import Loading from "../../components/IU/loading/loading";
import { Alert } from "../../components/IU/alert/alert";
import {
  IoNewspaperSharp,
  IoPaperPlane,
  IoSettingsSharp,
} from "react-icons/io5";
import axios from "axios";
import { url } from "../../api";
import Loading2 from "../IU/loading2/loading2";
import Xbt from "../../img/xbt-white-logo.svg";

const Head = ({
  home,
  setColor,
  color,
  datas,
  datas_personal,
  setIsAuthenticated,
}) => {
  const [menu, setMenu] = useState(false);
  const navigate = useNavigate();
  const [modalBurger, setModalBurger] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [local, setLocal] = useState("");
  const [address, setAddress] = useState();
  const [familyStatus, setFamilyStatus] = useState();

  const headers = {
    Authorization: `Bearer ${local}`,
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLocal(token);
    }
  }, []);

  useEffect(() => {
    if (local) {
      axios
        .get(url + "/currencies", { headers })
        .then((response) => {
          setCurrencies(response.data);
        })
        .catch((error) => {
          // console.error("Error:", error);
        });
    }
  }, [local]);

  useEffect(() => {
    if (datas_personal[0]?.verification.value == 2 && (datas_personal[0]?.verification.address == null || datas_personal[0]?.verification.family_status == null)) {
      setModalShow(true)
    }
  }, [datas_personal]);

  const handleLogout = () => {
    axios
      .post(url + "/auth/logout")
      .then((response) => {
        localStorage.removeItem("data_register");
        localStorage.removeItem("token");
        localStorage.removeItem("expires");
        window.location.replace("/login");
      })
      .catch((error) => {
        alert("Logout failed:", error);
      });
  };

  if (home) {
    handleLogout();
    setIsAuthenticated(false);
  }

  async function updatePersonalInformation(e) {
    e.preventDefault();
    setIsNavigating(true);
    try {
      const response = await axios.post(url + "/profile/personal/edit-profile", {
        "address": address,
        "family_status": familyStatus,
      }, { headers });
      if (response.data.response === true) {
        setIsNavigating(false);
        setModalShow(false);
        Alert("success", response.data.messages);
      }
    } catch (error) {
      Alert("error", error.response.data.messages);
      setIsNavigating(false);
    }
  }

  const balanceFilter = datas
    .filter((obj) => {
      return obj.currency.includes("USDT");
    })
    .map((el) => el.balance)

  return (
    <div className="head">
      {modalShow === true && (
        <div className="modal-window-v2">
          <div className="modal-window-v2-wrap check">
            <h2>Заполните анкету</h2>
            <p></p>
            <form onSubmit={updatePersonalInformation} className="form">
              <input
                className="mb-0"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Введите адрес проживания"
                autoFocus
                required
              />
              <select onChange={(e) => setFamilyStatus(e.target.value)} value={familyStatus} required>
                <option value="" disabled selected>Семейное положение</option>
                <option value="1">Холост/Незамужем</option>
                <option value="2">Женат/Замужем</option>
                <option value="3">Вдовец/Вдова</option>
              </select>
              <button type="submit">
                {isNavigating ? <Loading /> : "Продолжить"}
              </button>
            </form>
          </div>
        </div>
      )}
      <div className="contadfginer">
        <div className="titleProfiles">
          <h3 onClick={() => navigate("/")}>Главная</h3>
          <FaArrowLeftLong size={26} className="iconArrow__left" />
          <span>Мой кабинет</span>
        </div>
        <div className="saves__Lider">
          <div className="save_box one">
            {datas_personal[0] ? (
              <div className="save_box_user">
                <img
                  src={datas_personal[0].avatar}
                  alt={datas_personal[0].avatar}
                />
                <div>
                  <h1>
                    {datas_personal[0].firstname +

                      datas_personal[0].lastname}
                  </h1>
                  {datas_personal[0].verification.value == 1 ? (
                    <p className="activeited_false">
                      {datas_personal[0].verification.name}
                    </p>
                  ) : datas_personal[0].verification.value == 2 ? (
                    <p className="activeited_true">
                      {datas_personal[0].verification.name}
                    </p>
                  ) : datas_personal[0].verification.value == 3 ? (
                    <p className="activeited_norm">
                      {datas_personal[0].verification.name}
                    </p>
                  ) : datas_personal[0].verification.value == 4 ? (
                    <p className="activeited_not">
                      {datas_personal[0].verification.name}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="balanse">
            <div className="balanseFlex">
              <h4 className="balance-text">Ваш баланс:</h4>
              <h3 style={{ color: "#cfcfcf", textAlign: "right" }}>
                {currencies.total_balance ? (
                  currencies.total_balance
                ) : (
                  <Loading2 />
                )}
                <span
                  style={{
                    fontSize: "14px",
                    color: "#9b9b9b",
                    marginLeft: "5px",
                  }}
                >
                  USDT
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div className="wrapper_menu_Border"></div>
        <div className="wrapper_menu">
          <NavLink to="home" className="menu_router">
            Главная
          </NavLink>
          <NavLink to="payment-services" className="menu_router">
            Оплата услуг
          </NavLink>
          <NavLink to="top-up" className="menu_router">
            Пополнить
          </NavLink>
          <NavLink to="translation" className="menu_router">
            Вывести
          </NavLink>
          <NavLink to="sell-cryptocurrency" className="menu_router">
            Продать
          </NavLink>
          <NavLink to="buy-cryptocurrency" className="menu_router">
            Купить
          </NavLink>
          <NavLink to="rates" className="menu_router">
            Курс валют
          </NavLink>
          <NavLink to="settings" className="menu_router">
            Настройки
          </NavLink>
          <NavLink to="operations" className="menu_router">
            История операций
          </NavLink>
          <a className="menu_router" onClick={handleLogout}>Выйти</a>
        </div>
        <div className="wrapper_menu_Border"></div>
        {menu && (
          <>
            <div onClick={() => setMenu(false)} className="menu_modal"></div>
            <div onClick={() => setMenu(false)} className="menu">
              <div className="user none">
                <img
                  src={datas_personal[0] ? datas_personal[0].avatar : ""}
                  alt=""
                />
                <h1>
                  {datas_personal[0] ? datas_personal[0].firstname : "загрузка"}{" "}
                  {datas_personal[0] ? datas_personal[0].lastname : ""}
                  <RxExit
                    onClick={handleLogout}
                    style={{
                      marginLeft: 20,
                      cursor: "pointer",
                      color: color ? "var(--green)" : "var(--orange)",
                    }}
                    size={20}
                  />
                </h1>
              </div>
              <NavLink to="home" className="menu_router">
                <AiFillHome className="icon" size={25} />
                Главная
              </NavLink>
              <NavLink to="payment-services" className="menu_router">
                <FaWallet className="icon" size={25} />
                Оплата услуг
              </NavLink>
              <NavLink to="top-up" className="menu_router">
                <FaWallet className="icon" size={25} />
                Пополнить
              </NavLink>
              <NavLink to="translation" className="menu_router">
                <GiWallet className="icon" size={25} />
                Вывести
              </NavLink>
              <NavLink to="sell-cryptocurrency" className="menu_router">
                <RiMoneyDollarCircleLine className="icon" size={25} />
                Продать
              </NavLink>
              <NavLink to="buy-cryptocurrency" className="menu_router">
                <FaShoppingBag className="icon" size={25} />
                Купить
              </NavLink>
              <NavLink to="operations" className="menu_router">
                <IoNewspaperSharp className="icon" size={25} />
                История операции
              </NavLink>
              <NavLink to="settings" className="menu_router">
                <IoSettingsSharp className="icon" size={25} />
                Настройки профиля
              </NavLink>
              <a className="menu_router" onClick={handleLogout}>Выйти</a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Head;
