import React, { useState, useEffect } from "react";
import axios from "axios";
import { url } from "../../api";
import "./Contacts.css";
import unsplash from "../../img/1707295405.png";

const Contacts = () => {
  const [content, setContent] = useState('')

  useEffect(() => {
    axios
      .get(url + `/pages/contacts`)
      .then((response) => {
        if (response.data.detail.content) {
          setContent(response.data.detail.content)
        }
      })
  }, []);
  return (
    <div className="contacts">
      <div className="container">
        <div className="contact_form" dangerouslySetInnerHTML={{__html: (content) ? content : ''}}>
          
        </div>
        <div>
          <img className="bg_login" src={unsplash} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Contacts;
