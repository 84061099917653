import React, { useEffect } from "react";
import Meet from "../../components/Meet/Meet";
import Partners from "../../components/Advantages/Advantages";
import "./About.css";
import QRCodeAndroid from '../../img/qr-code-android-v1.svg'
import app66 from "../../img/app-66.svg";

const About = ({ color }) => {
  useEffect(() => {
    document.title = "О компании";
    const desc =
      "XBT.kg — это сервис отвечающий даже самым серьезным запросам! XBT не просто обменка, а самое главное дело в нашей жизни, нам действительно важен каждый наш клиент.";
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", desc);
  }, []);
  return (
    <div className="About">
      <Meet color={color} />
      <Partners />
      <div className="container container-custom">
        <div className="qwr-code">
          <div style={{ width: "100%" }}>
            <p style={{ color: "#fff" }}>
              Вы сможете пополнить баланс вашего аккаунта из любого, даже самого
              отдаленного места в Кыргызстане, а с помощью банковского перевода вы
              сможете пополнить свой аккаунт или вывести деньги в любую страну
              мира. Прозрачные комиссии, наличие лицензии, большие резервы и
              отличная репутация гарантируют успех ваших обменных операций!
            </p>
            <div className="home-button-wrap">
              <img src={QRCodeAndroid} alt="" />
              <div>
                <h5>Отсканируйте, чтобы загрузить приложение</h5>
                <h5 style={{ color: "#73F4AD" }}>iOS и Android</h5>
              </div>
            </div>
          </div>
          <img style={{ display: "flex", justifyContent: "end", alignItems: "end" }} src={app66} alt="" />
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default About;
